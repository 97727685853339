html {
  min-height: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(89.72deg, #041442 0.22%, #4e035f 99.75%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  color: #fff;
  font-family: "Roboto", sans-serif;
  width: 100%;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}

.container {
  width: 80%;
  margin: 0px auto;
}

/* =========================
        CSS Import
   ========================== */

@import url("./Components/Navbar//Navbar.css");
@import url("./Components/Banner/Banner.css");
@import url("./Components/Slider/Slider.css");
@import url("./Components/Footer/Footer.css");

.ReactModal__Overlay {
  background: linear-gradient(89.72deg, #0414427b, #4e035f72);
  /* background-color: red !important; */
}

.ReactModal__Content {
  background: linear-gradient(
    89.72deg,
    #041442 0.22%,
    #4e035f 99.75%
  ) !important;
  border: none !important;
  color: white !important;
}

.ReactModal__Content h2,
.ReactModal__Content h3 {
  text-align: center;
  color: white !important;
}
