.footer {
  padding: 80px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer img {
  height: 20px;
  width: 20px;
  align-content: center;
  margin-right: 10px;
}
.detail {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 80px auto 40px;
}
.detail button {
  border: none;
  background: linear-gradient(89.72deg, #5b86ff 0.22%, #d515fe 99.75%);
  border-radius: 8px;
  padding: 8px 35px;
  margin-left: 20px;
  color: #fff;
  font-weight: 500;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}
